<template>
  <div class="qrcodes-types">
    <v-row>
      <v-col cols="12" sm="6" md="6" lg="4" v-for="type in qrcodesTypes" :key="type.key">
        <v-hover v-slot="{ hover }">
          <v-card
            class="qrcode-type-card"
            :class="{ 'on-hover': hover }"
            :elevation="hover ? 6 : 2"
            :to="{ name: 'qrcode.create', query: { codeType: type.key } }"
            :disabled="type.disabled"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-avatar size="60">
                <v-icon class="lighten-1" :class="hover ? 'primary' : 'grey'" size="28" dark>
                  {{ type.icon }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <div v-if="type.disabled" :style="{ whiteSpace: 'nowrap' }" class="orange--text">
                  {{ $t("GlobalUI.ComingSoon") }}
                </div>
                <v-list-item-title class="headline" v-html="type.label" />
                <v-list-item-subtitle v-html="type.explanation" />
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { QRCODE_TYPES } from "../../config";

export default {
  name: "VQRCodesTypes",

  computed: {
    qrcodesTypes() {
      return QRCODE_TYPES.map((type) => ({
        ...type,
        label: this.$t(`QRCodes.Type${type.label}`),
        explanation: this.$t(`QRCodes.Type${type.label}Explanation`),
        disabled: ["ROOM"].includes(type.key),
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.qrcode-type-card {
  &:hover {
    cursor: pointer;
  }
}
</style>