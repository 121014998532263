<template>
  <div class="qrcodes-types-page">
    <v-qrcodes-types />
  </div>
</template>

<script>
import VQRCodesTypes from "../../components/qrcodes/VQRCodesTypes";

export default {
  name: "QRCodesTypes",

  layout: "default",

  components: {
    "v-qrcodes-types": VQRCodesTypes,
  },

  metaInfo() {
    return { title: "QR Codes" };
  },
};
</script>
